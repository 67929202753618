import React from "react";
import {Col, Row} from "antd";
import {UserOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";

import logo from "./logoHead.svg";
import {getUserInfo} from "../../service/authService";

function Header() {
    const userInfo = getUserInfo();

    return (
        <Row justify='center' align='middle' style={{ height: '60px', backgroundColor: '#1B1C1B', color: '#FFFFFF' }}>
            <Col span={8} style={{ paddingLeft: '10px' }}>
                <img src={logo} height='40' alt='Advance Agent' />
            </Col>
            <Col span={4} offset={12} align='right' style={{ paddingRight: '10px' }}>
                <UserOutlined /> {userInfo.firstName} {userInfo.lastName} (<Link to='/logout'>выйти</Link>)
            </Col>
        </Row>
    )
}

export default Header;