import axios from 'axios'
import {serialize} from 'object-to-formdata';

export const clearUserInfo = () => {
    localStorage.removeItem('user-info');
};

export const getUserInfo = () => {
    return JSON.parse(localStorage.getItem('user-info'));
};

export const initUserInfo = () => {
    return new Promise((resolve, reject) => {
        axios.get('/api/get-user-info')
            .then((response) => {
                localStorage.setItem('user-info', JSON.stringify(response.data));
                resolve(response);
            })
            .catch(reject);
    });
};

export const authenticate = (request) => {
    return axios.post(
        '/api/login',
        serialize(request),
        {headers: {'Content-Type': 'application/x-www-form-urlencoded'}}
    );
};

export const logout = (callback) => {
    axios
        .post('/api/logout')
        .then((response) => {
            clearUserInfo();
            callback(response);
        })
        .catch(callback);
};
