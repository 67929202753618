import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';

import {Alert, Button, Checkbox, Form, Input, Spin} from 'antd';
import {LoadingOutlined, LockOutlined, UserOutlined} from '@ant-design/icons';

import './login.css';
import logo from './logo.png';
import {authenticate} from "../../service/authService";

function LoginPage() {
    const [isError, setError] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const history = useHistory();

    let handleSubmit = async (request) => {
        setLoading(true);
        setError(false);

        authenticate(request)
            .then(() => history.push('/'))
            .catch(() => {
                setLoading(false);
                setError(true);
            });
    };

    return (
        <Form onFinish={handleSubmit} className='login-form'>
            <img className='login-form__image' src={logo} alt=''/>
            {isError &&
                <Alert message='Неверный логин или пароль!' type='error' className='login-form__failed-alert'/>
            }
            <Form.Item name="username" rules={[{required: true, message: 'Пожалуйста, введите email!'}]}>
                <Input prefix={<UserOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                       placeholder='Email'/>
            </Form.Item>
            <Form.Item name="password" rules={[{required: true, message: 'Пожалуйста, введите пароль!'}]}>
                <Input prefix={<LockOutlined style={{color: 'rgba(0,0,0,.25)'}}/>} type='password'
                       placeholder='Пароль'/>
            </Form.Item>
            <Form.Item name="rememberMe" initialValue={false} valuePropName="checked">
                <Checkbox>Запомнить меня</Checkbox>
            </Form.Item>
            <Button type='primary' htmlType='submit' className='login-form__button'>
                {isLoading &&
                    <Spin indicator={<LoadingOutlined className='login-form__button-loader' spin/>}/>
                }
                Войти
            </Button>
        </Form>
    )
}

export default LoginPage;