import React, { useEffect } from 'react';
import {Row, Spin} from "antd";
import {logout} from "../service/authService";
import {useHistory} from "react-router-dom";

function LogoutPage() {
    const history = useHistory();

    useEffect(() => {
        logout(() => history.push('/'));
    });

    return (
        <Row type="flex" justify="space-around" align="middle" style={{minHeight: '100vh'}}>
            <Spin size='large' />
        </Row>
    );
}

export default LogoutPage;