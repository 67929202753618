import React from 'react';
import {List} from "antd";
import {pointTypes} from "./PointType";
import moment from "moment";

function MapLegend(props) {
    const {points} = props;

    const findPointType = (point) => pointTypes.find(type => type.label === point.orderType);

    const getImage = (point) => findPointType(point).marker.iconUrl;

    const getTitle = (point) => findPointType(point).name;

    const getDescription = (point) => {
        const time = moment(point.checkDate).format('HH:mm:ss');
        if (point.clientName) {
            return `${time} ${point.clientName}`
        } else {
            return `${time}`
        }
    };

    return (
        <List className='map-legend'
              itemLayout="horizontal"
              dataSource={points}
              renderItem={point => (
                  <List.Item>
                      <List.Item.Meta
                          avatar={<img src={getImage(point)} width={20} alt={point.orderType}/>}
                          title={getTitle(point)}
                          description={getDescription(point)}
                      />
                  </List.Item>
              )}
        />
    )
}

export default MapLegend;