import React from 'react';
import {Switch, Form} from "antd";

function PointsFilter(props) {
    return (
        <Form.Item className='points-filter' label='Не показывать перемещения'>
            <Switch onChange={props.onChange} />
        </Form.Item>
    )
}

export default PointsFilter;