import React from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

import MainPage from "./pages/MainPage";
import LoginPage from "./pages/login/LoginPage";
import OnlinePage from "./pages/online/OnlinePage";
import LogoutPage from "./pages/LogoutPage";

function App() {
    return (
        <Router>
            <Switch>
                <Route path='/' component={MainPage} exact />
                <Route path='/login' component={LoginPage} />
                <Route path='/logout' component={LogoutPage} />
                <Route path='/online' component={OnlinePage} />
            </Switch>
        </Router>
    );
}

export default App;
