import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {Row, Spin} from "antd";

import {initUserInfo} from "../service/authService";

function MainPage() {
    const history = useHistory();

    useEffect(() => {
        initUserInfo()
            .then(() => history.push('/online'))
            .catch(() => history.push('/login'));
    });

    return (
        <Row type="flex" justify="space-around" align="middle" style={{minHeight: '100vh'}}>
            <Spin size='large' />
        </Row>
    );
}

export default MainPage;