import React, {useState} from 'react';
import {loadPoints} from "../../service/pointsService";

import 'leaflet/dist/leaflet.css'
import './OnlinePage.css';

import AgentFilterForm from "./AgentFilterForm";
import PointsMap from "./PointsMap";
import Header from "./Header";
import MapLegend from "./MapLegend";
import PointsFilter from "./PointsFilter";

function OnlinePage() {
    const [points, setPoints] = useState([]);
    const [withoutRoute, setWithoutRoute] = useState(false);
    let filteredPoints = points;

    if (withoutRoute) {
        filteredPoints = points.filter(point => point.orderType !== 'ROUTE')
    }

    const onLoadPoints = (data) => {
        loadPoints(data).then(setPoints);
    };

    return (
        <div>
            <Header/>
            <PointsMap points={filteredPoints}/>
            <div className="content-container">
                <AgentFilterForm onChange={onLoadPoints}/>
                <PointsFilter onChange={setWithoutRoute}/>
                <MapLegend points={filteredPoints}/>
            </div>
        </div>
    );
}

export default OnlinePage;