import axios from './axios';

export const loadAgents = (date) => {
    return new Promise((resolve, reject) => {
        axios.get('/api/online/get-agents/' + date.format('YYYY/MM/DD'))
            .then((response) => resolve(response.data))
            .catch((error) => reject(error));
    });
};

export const loadPoints = ({date, userCode}) => {
    return new Promise((resolve, reject) => {
        axios.get('/api/online/get-route/' + userCode + '/' + date.format('YYYY/MM/DD'))
            .then((response) => resolve(response.data))
            .catch((error) => reject(error));
    });
};