import React from 'react';
import {Map, TileLayer, Marker, Popup} from "react-leaflet";
import {latLngBounds} from "leaflet";
import Polyline from 'react-leaflet-arrowheads'
import * as L from "leaflet";
import moment from 'moment';

import {pointTypes} from './PointType';

const defaultIcon = {
    iconSize: [25, 41],
    iconAnchor: [12, 40],
    popupAnchor: [-3, -76],
    shadowSize: [41, 41],
    shadowAnchor: [11, 40]
};

const arrowheads = {
    yawn: 40,
    color: 'black',
    size: '4%'
};

function PointsMap(props) {
    const { points } = props;

    const getMapCenter = (points) => {
        let mapCenter = [0, 0];

        if (points.length === 0) {
            return [51.1678322, 71.4202913];
        }

        for (let i = 0; i < points.length; i++) {
            mapCenter[0] += points[i].longitude;
            mapCenter[1] += points[i].latitude;
        }

        return mapCenter.map(item => item / points.length);
    };

    const getPointIconByLabel = (label) => {
        return L.icon({
            ...defaultIcon,
            ...pointTypes.find(type => type.label === label).marker
        });
    };

    const createPointTooltip = (item) => {
        return (
            <div>
                { item.clientName &&
                    <p><b>Клиент: </b>{item.clientName}</p>
                }
                <p><b>Дата: </b> {moment(item.checkDate).format('DD.MM.YYYY HH:mm:ss')}</p>
            </div>
        );
    };

    const createBounds = (points) => {
        if (points.length === 0) {
            return undefined;
        }

        const longitudes = points.map((point) => point.longitude);
        const latitudes = points.map((point) => point.latitude);

        return latLngBounds(
            [Math.min.apply(null, longitudes), Math.max.apply(null, latitudes)],
            [Math.max.apply(null, longitudes), Math.min.apply(null, latitudes)]
        );
    };

    return (
        <Map center={getMapCenter(points)} bounds={createBounds(points)} zoom={1}>
            <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                       attribution='© OpenStreetMap'/>
            {points.map((item) => (
                <Marker key={item.id} position={[item.longitude, item.latitude]}
                        icon={getPointIconByLabel(item.orderType)}
                        zIndexOffset={1}>
                    <Popup>{createPointTooltip(item)}</Popup>
                </Marker>
            ))}
            <Polyline positions={points.map((point) => [point.longitude, point.latitude])} color="red" arrowheads={arrowheads}/>
        </Map>
    );
}

export default PointsMap;