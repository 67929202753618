import axios from 'axios';
import {clearUserInfo} from "./authService";

axios.interceptors.response.use(
    (response) => response,
    (error) => {
        const {status} = error.response;
        const {url} = error.config;

        if (status === 401 && url.indexOf('get-user-info') < 0 && url.indexOf('login') < 0) {
            clearUserInfo();
            window.open('/', '_self')
        }
        return Promise.reject(error)
    }
);

export default axios;