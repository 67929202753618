import React, {useEffect, useState} from "react";
import {DatePicker, Form, Select} from "antd";
import moment from 'moment';

import {loadAgents} from "../../service/pointsService";

const layout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 20,
    },
};

function AgentFilterForm(props) {
    const [date, setDate] = useState(moment());
    const [userCode, setUserCode] = useState();
    const [agents, setAgents] = useState([]);
    const [agentsSelectDisabled, setAgentsSelectDisabled] = useState(false);

    const loadAgentsBy = () => {
        setAgentsSelectDisabled(true);
        loadAgents(date).then(agents => {
            setAgentsSelectDisabled(false);
            setAgents(agents);
            if (userCode && !agents.some(agent => agent.userCode === userCode)) {
                setUserCode(null);
            }
        });
    };
    useEffect(loadAgentsBy, [date]);

    const onChange = () => {
        if (userCode) {
            props.onChange({date, userCode});
        }
    };
    useEffect(onChange, [userCode, date]);

    return (
        <Form {...layout} className='agents-filter'>
            <Form.Item name="date" label='Дата'>
                <DatePicker format='DD.MM.YYYY' defaultValue={date} onChange={setDate} />
            </Form.Item>
            <Form.Item name="userCode" label='Агент'>
                <Select disabled={agentsSelectDisabled} loading={agentsSelectDisabled}
                        value={userCode} onChange={setUserCode} placeholder="Выберите...">
                    {
                        agents.map(agent => (
                            <Select.Option value={agent.userCode} key={agent.userCode}>
                                { agent.firstName + ' ' + agent.lastName }
                            </Select.Option>
                        ))
                    }
                </Select>
            </Form.Item>
        </Form>
    );
}

export default AgentFilterForm;