const pointTypes = [
    {
        label: 'ORDER', // Заказ
        name: 'Заказ',
        marker: {
            iconUrl: '/images/order.png'
        }
    }, {
        label: 'ORDER_IN_ROUTE', // Заказ в маршруте
        name: 'Заказ в маршруте',
        marker: {
            iconUrl: '/images/order_in_route.png'
        }
    }, {
        label: 'REFUND', //Возврат
        name: 'Возврат',
        marker: {
            iconUrl: '/images/refund.png'
        }
    }, {
        label: 'VISIT', // Посещение
        name: 'Посещение',
        marker: {
            iconUrl: '/images/visit.png'
        }
    }, {
        label: 'ROUTE', // Перемещение
        name: 'Перемещение',
        marker: {
            iconUrl: '/images/route.png',
            iconSize: [18, 30],
            iconAnchor: [9, 30],
        }
    }
];

export {
    pointTypes
}