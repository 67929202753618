import React from 'react';
import ReactDOM from 'react-dom';

import { ConfigProvider } from 'antd';
import 'antd/dist/antd.css';
import Ru_RU from 'antd/es/locale/ru_RU';

import App from './App';

ReactDOM.render(
  <React.StrictMode>
      <ConfigProvider locale={Ru_RU}>
          <App />
      </ConfigProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
